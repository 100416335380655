#services-section {
  position: relative;
  padding: 7% 2rem;
  background: var(--section-bg-bottom);
}

.services-container {
  max-width: 1440px;
  margin: 0 auto;
  padding: 3rem 2rem 0;
  background-color: var(--blue);
  background-color: var(--tertiary-color);
  border-radius: 25px;
}

.services-container-inner {
  max-width: 1200px;
  margin: 0 auto;
}

/* SERVICES HEADER */
.services-header {
  margin-bottom: 3rem;
}

.services-header h1 {
  color: var(--main-bg-dark);
  font-size: 3rem;
  font-weight: 700;
  line-height: 1;
  margin-bottom: 4.5rem;
}

.service-group {
  display: flex;
  justify-content: space-evenly;
  gap: 1rem;
}

.service-group button {
  color: var(--main-bg-dark);
  font-size: 1.1rem;
  font-weight: 600;
  letter-spacing: 1.5px;
  padding: 0.5rem 1.25rem;
  border: 2px solid var(--primary-darkBlue);
  border-radius: 10px;
}

.service-group button:hover {
  color: var(--primary-darkBlue);
  box-shadow: 1px 1px 6px rgba(0, 0, 0, 0.2), 0px 0px 2px rgba(0, 0, 0, 0.2);
}

.service-group .active-index {
  color: var(--text-white);
  border: none;
  background-color: var(--primary-darkBlue);
  box-shadow: 1px 1px 6px rgba(0, 0, 0, 0.2), 0px 0px 2px rgba(0, 0, 0, 0.2);
  transform: scale(1.1);
  transition: transform 0.2s ease-in-out;
}
.service-group .active-index:hover {
  color: var(--text-white);
}

/* SERVICES GRID */
.services-grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 1rem 4rem;
  align-items: center;
  position: relative;
  z-index: 100;
  /* height: 500px; */
}

.services {
  display: flex;
  flex-direction: column;
}

.service-description {
  font-size: 1.05rem;
  padding: 1rem;
}

.service-description h2 {
  color: var(--main-bg-dark);
  font-size: 1.7rem;
  font-weight: 600;
  margin-bottom: 1rem;
}

.service-description p {
  margin-bottom: 1rem;
}

.service-description ul {
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
  margin-bottom: 2.5rem;
}

.service-description ul li {
  display: flex;
  align-items: center;
  gap: 0.75rem;
}

.service-description .icon {
  color: var(--primary-darkBlue);
}

.service-description .service-link {
  padding: 0.5rem 1.25rem;
  font-weight: 600;
  color: var(--text-white);
  background-color: var(--complementary-accent);
  border: 2px solid transparent;
  border-radius: 5px;
  margin-top: 1rem;
  transition: color 0.1s ease-in-out;
}

.service-description .service-link:hover {
  color: var(--complementary-accent);
  background-color: transparent;
  border-color: var(--complementary-accent);
}

.nav-btn-container {
  display: flex;
  gap: 3rem;
  justify-self: center;
  grid-column: span 2;
  padding: 1rem 0;
}

.nav-btn-container .icon {
  border: 2px solid var(--complementary-accent);
  padding: 0.25rem;
  border-radius: 100%;
  color: var(--complementary-accent);
  cursor: pointer;
  transition: color 0.05s ease-in-out, transform 0.2s ease-in-out;
}

.nav-btn-container .icon:hover {
  background-color: var(--complementary-accent);
  color: white;
}
.nav-btn-container .icon-left:hover {
  transform: translateX(-5px);
}
.nav-btn-container .icon-right:hover {
  transform: translateX(5px);
}

.services-grid img {
  border-radius: 25px;
  border-bottom-right-radius: 200px;
  object-fit: cover;
  height: 350px;
  width: 100%;
  align-self: stretch;
  animation: slideIn 1.4s;
}

/* @keyframes slideIn {
  from {
    transform: translateX(-100%);
    opacity: 0;
  }
  to {
    transform: translateX(100%);
    opacity: 1;
  }
} */

/* SHAPES */
.circle,
.circle-blue,
.circle-yellow,
.square,
.square-green {
  position: absolute;
  z-index: -100;
  width: 100px;
  height: 100px;
  border-radius: 100%;
  opacity: 0.3;
  overflow: hidden;
}

.circle {
  top: -50px;
  left: -50px;
  background-color: var(--complementary-accent);
}

.square,
.square-green {
  top: 125px;
  left: 400px;
  background-color: var(--main-bg-dark);
  border-radius: 5px;
}

.square-green {
  top: 175px;
  left: 450px;
  transform: rotate(30deg);
  background-color: var(--accent);
}

.circle-blue {
  top: -50px;
  right: -50px;
  background-color: var(--primary-darkBlue);
}
.circle-yellow {
  top: 25px;
  right: -75px;
  background-color: var(--accent);
}

@media (max-width: 1200px) {
  .services-grid {
    grid-template-columns: 1fr 1fr;
  }

  .circle-blue {
    top: -35px;
    right: 50px;
  }
  .circle-yellow {
    top: 10px;
    right: -20px;
  }
}

@media (max-width: 992px) {
}

@media (max-width: 768px) {
  #services-section {
    padding: 5rem 0rem;
  }
  .service-group {
    display: none;
  }

  .services-container {
    padding: 3rem 1rem 1.5rem;
    border-radius: 15px;
  }

  .services-grid {
    row-gap: 1rem;
  }

  .services-grid img {
    order: -1;
    border-radius: 10px;
    height: 250px;
  }

  .nav-btn-container {
    grid-column: span 1;
    padding: 0.25rem 0;
  }

  .nav-btn-container .icon {
    padding: 0.05rem;
  }

  .nav-btn-container .icon:hover {
    background-color: none;
    color: white;
  }

  .circle-blue {
    top: -35px;
    right: 50px;
    background-color: var(--primary-darkBlue);
  }
  .circle-yellow {
    top: 10px;
    right: -10px;
    background-color: var(--accent);
  }

  .square,
  .square-green {
    display: none;
  }
}

@media (max-width: 575px) {
  #services-section {
    padding: 5rem 0rem;
  }

  .services-header h1 {
    font-size: 2.5rem;
    margin-bottom: 3.5rem;
  }

  .service-group {
    display: none;
  }

  .services-grid {
    grid-template-columns: 1fr;
    row-gap: 1rem;
  }
  .service-description .service-link {
    padding: 0.25rem 0.5rem;
    font-size: 1.1rem;
  }
}
