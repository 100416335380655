.admission-home {
  padding: 12.5rem 1rem;
  background: url(/public/ai_4girls_banner.jpg);
  /* background: url(/public/new_images/girl_group.jpg); */
  background-size: cover;
  background-position: top center;
}

.admission-container-home {
  text-align: center;
  max-width: 1200px;
  margin: 0 auto;
}

.admission-container-home h1 {
  font-size: 2.5rem;
  color: var(--text-white);
  font-weight: 600;
  margin-bottom: 3rem;
  text-shadow: 2px 2px 2px rgba(0, 0, 0, 0.5);
}

.admission-link {
  color: #000;
  font-size: 1.3rem;
  text-transform: none;
  font-weight: 700;
  background-color: var(--accent);
  padding: 1rem 2rem;
  border-radius: 10px;
  box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.2);
}

.admission-link:hover {
  background-color: var(--accent);
  opacity: 0.8;
}

@media (max-width: 992px) {
  .admission-home {
    padding: 7.5rem 1rem;
  }
}

@media (max-width: 768px) {
  .admission-home {
    padding: 5rem 1rem;
  }

  .admission-container-home h1 {
    font-size: 1.5rem;
    margin-bottom: 2.25rem;
  }

  .admission-link {
    font-size: 1.2rem;
    padding: 1rem 2rem;
  }
}

@media (max-width: 575px) {
  .admission-container-home h1 {
    font-size: 2rem;
    margin-bottom: 2.25rem;
  }

  .admission-link {
    font-size: 1.1rem;
    padding: 1rem 1.5rem;
  }
}
