.sliderStyles {
  height: 100%;
  position: relative;
}

.slideStyles {
  position: relative;
  width: 100%;
  height: 100%;
  background-position: center;
  background-size: cover;
}

.slideStyles {
  height: auto;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: auto auto 40px;
  width: 100%;
  box-shadow: 2px 2px 12px rgba(0, 0, 0, 0.4);
}

.slideStyles::before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: linear-gradient(
    to left,
    rgba(0, 0, 0, 0.4),
    rgba(0, 0, 0, 0.2),
    rgba(0, 0, 0, 0.1)
  );
  z-index: 1;
}

.dot-container {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
  z-index: 2;
}

.dotStyle {
  color: white;
  cursor: pointer;
  font-weight: bold;
  font-size: 30px;
  padding-bottom: 1rem;
}

.dot {
  color: var(--primary-darkBlue);
  transform: scale(1.1);
}

.left-arrow,
.right-arrow {
  position: absolute;
  top: 50%;
  transform: translate(0, -50%);
  font-size: 45px;
  color: #fff;
  z-index: 3;
  cursor: pointer;
  padding: 0.5rem;
  border-radius: 10px;
  background-color: transparent;
  transition: background-color 0.1s ease-in-out;
}

.left-arrow {
  left: 32px;
}
.right-arrow {
  right: 32px;
}

.left-arrow:hover,
.right-arrow:hover {
  color: var(--main-bg-color);
  background-color: var(--subtle-background);
  box-shadow: var(--box-shadow);
}

@media (max-width: 992px) {
  .slideStyles {
    border-bottom-right-radius: 0;
  }
  .slideStyles::before {
    border-bottom-right-radius: 0;
  }
}

@media (max-width: 768px) {
}

@media (max-width: 575px) {
  .dot-container {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 0.5rem;
    z-index: 2;
  }

  .left-arrow {
    left: 4px;
  }
  .right-arrow {
    right: 4px;
  }
}
