#about-section {
  /* background: var(--section-bg); */
  /* padding: 0 2rem; */
}

.header {
  color: var(--text-white);
  background: var(--primary-darkBlue);
  background-color: var(--main-bg-dark);
}

#about .header-container {
  padding: 2rem 1rem;
  text-align: left;
}

.header-container h2 {
  color: var(--text-white);
  font-size: 4rem;
  font-weight: 600;
  line-height: 1.1;
  padding: 2.5rem 0;
  border-top: 2px solid rgba(206, 206, 206, 0.5);
  border-bottom: 2px solid rgba(206, 206, 206, 0.5);
}

#about .aboutpage-container {
  display: grid;
  grid-template-columns: 1fr 300px;
  gap: 2rem;
  max-width: 1200px;
  margin: 0 auto;
  transition: 0.2s all;
}

.aboutpage-container main {
  padding: 4rem 1rem;
}

/* SIDEBAR */
#sidebar {
  margin-top: -2rem;
}
.sidebar-container h3 {
  font-size: 2rem;
  font-weight: 500;
  padding: 1.5rem 0 1.5rem 1rem;
  color: var(--text-white);
  background-color: var(--content-background);
  margin-bottom: 1rem;
}

.sidebar-container .link {
  display: inline-block;
  width: 100%;
  font-size: 1rem;
  padding: 0.75rem 1rem;
  border-bottom: 2px solid var(--subtle-background);
}

.sidebar-container .link:hover {
  background-color: var(--subtle-background);
}

.sidebar-container .link.active {
  border-left: 4px solid var(--main-bg-dark);
  padding-left: 1.5rem;
  transition: 0.3s all;
}

/* MEDIA QUERIES */

@media (max-width: 992px) {
}

@media (max-width: 768px) {
  #about .aboutpage-container {
    grid-template-columns: 1fr 200px;
    row-gap: 2rem;
  }
  .header-container h2 {
    text-align: center;
    font-size: 3.5rem;
    line-height: 1;
  }

  .sidebar-container .list {
    /* visibility: hidden; */
  }
}

@media (max-width: 575px) {
  #about .aboutpage-container {
    grid-template-columns: 1fr;
  }
  .header-container h2 {
    font-size: 2.5rem;
  }
  #sidebar {
    order: -1;
  }

  .aboutpage-container main {
    padding: 1rem 1rem;
  }
}
