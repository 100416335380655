.leadership,
.staff {
  margin-bottom: 4rem;
}
.leadership h1,
.staff h1 {
  font-size: 2.5rem;
  padding-bottom: 0.5rem;
  border-bottom: 2px solid var(--subtle-background);
  margin-bottom: 1rem;
}

.leadership .profile-container {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 3rem 1rem;
}

.profile-container {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 3rem 1rem;
}

.profile-container img {
  height: 250px;
  height: auto;
  width: 100%;
  border-radius: 10px;
  object-fit: cover;
  margin-bottom: 0.5rem;
}

/* MEDIA QUERIES */

@media (max-width: 992px) {
}

@media (max-width: 768px) {
}

@media (max-width: 575px) {
  .leadership .profile-container {
    grid-template-columns: 1fr;
    row-gap: 2rem;
  }
  .profile-container {
    grid-template-columns: 1fr;
    row-gap: 2rem;
  }
}
