.events-header {
  background: url(https://picsum.photos/id/50/320/600?grayscale);
  background-size: cover;
  background-position: center;
}

.event-list {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 3rem 2rem;
}

.event-item,
.event-item .link {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}
.event-item span {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  font-size: 14px;
  padding: 0.25rem 1rem;
  background-color: var(--accent);
  border-radius: 50px;
  align-self: flex-end;
}

.event-item .link img {
  border-radius: 20px;
  box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.3);
}
.event-item .link h2 {
  font-size: 1.25rem;
  font-weight: 600;
}
.event-item .link p {
  width: 100%;
}

/* TABLET SCREENS */
@media (max-width: 992px) {
  .event-list {
    gap: 2rem 1.5rem;
  }
}

/* Media Query for mobile layout */
@media (max-width: 768px) {
  .event-list {
    grid-template-columns: 1fr 1fr;
    /* align-items: stretch; */
    gap: 2rem 2rem;
  }
}

@media (max-width: 575px) {
  .event-list {
    grid-template-columns: 1fr;
    /* align-items: stretch; */
    row-gap: 1.5rem;
  }
}
