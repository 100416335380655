/* HERO SECTION STYLES */

.hero-section {
  padding: 1rem 2rem;
  z-index: 2;
  width: 100%;
  height: 500px;
  margin-top: 30px;
}

.hero-container {
  display: grid;
  grid-template-columns: 3.5fr 0.75fr 1.5fr;
  align-items: center;
  gap: 1rem;
  height: 100%;
  max-width: 1200px;
  margin: 0 auto;
}

.hero-container > div:first-child {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 2rem;
}

.glass-effect {
  backdrop-filter: blur(1px);
  background: rgba(255, 255, 255, 0.2);
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  border: 1px solid rgba(255, 255, 255, 0.3);
}

.hero-container .heading {
  font-size: 2.5rem;
  font-weight: 600;
  line-height: 1;
  color: var(--text-color2);
  color: #fff;
  text-shadow: 2px 2px 3px rgba(0, 0, 0, 0.2);
}

.hero-container .btn {
  color: #000;
  font-size: 1.3rem;
  text-transform: uppercase;
  font-weight: 700;
  background-color: var(--accent);
  padding: 1rem 2rem;
  border-radius: 10px;
  box-shadow: 2px 2px 6px 6px rgba(0, 0, 0, 0.2);
}

.hero-container .btn:hover {
  color: #000;
  background-color: var(--accent);
  opacity: 0.9;
}

.heading .school-title {
  font-size: 4.5rem;
}

.green {
  color: var(--complementary-accent);
  color: rgb(0, 255, 0);
}
.yellow {
  color: var(--accent);
}
.blue {
  color: var(--text-color);
  color: rgb(0, 102, 255);
}
.lightblue {
  color: var(--content-background);
  color: red;
}

/* UPCOMING EVENTS */

.upcoming-events {
  /* display: none; */
  align-self: stretch;
  color: #fff;
  border: 2px solid white;
  border-radius: 10px;
  background-color: rgba(0, 0, 0, 0.24);
  /* background-color: var(--text-white); */
  opacity: 0.9;
  transition: 0.2s all;
  box-shadow: 2px 2px 6px 6px rgba(0, 0, 0, 0.2);
}

.upcoming-events h2 {
  color: var(--text-white);
  align-self: stretch;
  justify-self: center;
  text-align: center;
  text-align: center;
  font-size: 1.5rem;
  font-weight: 600;
  padding: 0.5rem 0;
  margin-bottom: 1rem;
  border-bottom: 2px solid var(--accent);
}

.event {
  display: flex;
  flex-direction: column;
}

.event h3 {
  font-size: 1.1rem;
  font-weight: 600;
}

.event > * {
  font-size: 14px;
  padding: 0.75rem 1.5rem;
  border-bottom: 2px solid #000;
  transition: 0.2s all;
}

.event-link {
  font-size: 1rem;
  padding: 0.25rem 1rem;
  margin: 1rem 0;
  color: var(--text-white);
  background-color: var(--primary-darkBlue);
  font-weight: 600;
  text-align: center;
  width: 60%;
  align-self: center;
  justify-self: end;
  border-radius: 5px;
}

/* ANIMATED TEXT */
.drop-in {
  animation: dropIn 1s ease-in-out;
}
.drop-out {
  animation: dropOut 1s ease-in-out;
}

/* UPCOMING EVENTS */
.event-drop-in {
  animation: eventDropIn 1s ease-in;
}
.event-drop-out {
  animation: eventDropOut 0.5s ease-out;
}

/* ANIMATE SCHOOL TEXT */
@keyframes dropIn {
  0% {
    transform: translateY(100%);
    opacity: 0;
    /* Start with zero opacity */
  }
  100% {
    transform: translateY(0);
    opacity: 1;
    /* Fully visible*/
  }
}

@keyframes dropOut {
  0% {
    transform: translateY(0);
    opacity: 1;
  }
  100% {
    transform: translateY(100%);
    opacity: 0;
  }
}

@keyframes eventDropIn {
  0% {
    opacity: 0;
    /* transform: translateX(200%); */
    /* Start with zero opacity */
  }
  100% {
    opacity: 1;
    /* transform: translateX(0); */
    /* Fully visible*/
  }
}

@keyframes eventDropOut {
  0% {
    /* transform: translateX(0); */
    opacity: 1;
  }
  100% {
    /* transform: translateX(200%); */
    opacity: 0;
  }
}

@media (max-width: 1200px) {
  .upcoming-events {
    display: none;
  }
}

@media (max-width: 992px) {
  .hero-section {
    padding: 0 3rem;
  }
  .hero-container {
    grid-template-columns: 1fr;
    text-align: center;
  }
  .hero-container > div:first-child {
    justify-content: center;
    align-items: center;
    gap: 2rem;
  }
}

@media (max-width: 768px) {
  .hero-container {
    grid-template-columns: 1fr;
    text-align: center;
  }
  .hero-container > div {
    align-items: center;
    gap: 2rem;
  }

  .hero-container > div:first-child {
    align-items: center;
  }

  .hero-container .btn {
    font-size: 1.1rem;
    padding: 0.75rem 1.5rem;
  }

  .upcoming-events {
    display: none;
  }
}

@media (max-width: 575px) {
  .hero-container .heading {
    font-size: 1.5rem;
    font-weight: 600;
    line-height: 1.2;
  }

  .heading .school-title {
    font-size: 2.75rem;
  }
}
