.admission-header {
  background: url(/public/devotion2.jpg);
  background-size: cover;
  background-position: 60% 40%;
}

.admission-main {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 1rem 4rem;
}

.admission-main article {
  margin-bottom: 3.5rem;
}

.admission-main .footnote {
  margin-bottom: 3.5rem;
  text-align: center;
}

.new-admission {
  display: grid;
  grid-template-columns: 1fr 400px;
  gap: 3rem;
  width: auto;
  position: relative;
}

.new-admission > ul {
  list-style-type: square;
  width: auto;
}

.new-admission > ul > li,
.new-admission > ul > .phone-info section > li,
.new-admission > ul > .laptop-info section > li {
  margin-bottom: 2rem;
}

.new-admission > ul > div {
  list-style-type: none;
  border: 1px solid grey;
  padding: 2rem 1rem;
  border-radius: 8px;
}
.new-admission > ul > div > li {
  margin-bottom: 1.5rem;
}

.css-1hb5ode {
  padding-inline-start: 0 !important;
  padding-inline-end: 0 !important;
}

.css-uttm9k {
  padding-inline-start: 0;
  padding-inline-end: 0;
}

.chakra-accordion__button {
  padding-left: 0;
  padding-right: 0;
}

.new-admission article h2 {
  font-size: 1.5rem;
}

.registration-steps {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.new-admission .registration-steps li {
  display: flex;
  flex-direction: column;
}

.registration-steps li > span {
  font-weight: bold;
}

.requirement-steps-sidebar {
  list-style-type: disc;
  padding-left: 1rem;
  margin-bottom: 1rem;
}
.requirements {
  list-style-type: disc;
  padding-left: 1rem;
  margin-bottom: 1rem;
}

.new-admission > ul > .laptop-info section > li > ul,
.new-admission > ul > .phone-info section > li > ul {
  list-style-type: circle;
  padding-left: 1rem;
  margin-bottom: 1rem;
}
.new-admission > ul > li > ul > li > ul {
  list-style-type: disc;
  padding-left: 1rem;
}

.school-time {
  padding: 0 !important;
}

.school-time li {
  display: grid;
  grid-template-columns: 200px 50px 200px;
  padding-left: 0;
}

.account-details li {
  display: grid;
  grid-template-columns: 100px 20px auto;
  margin-bottom: 0.5rem;
}

.fee-breakdown h2 {
  font-size: 1.4rem;
  font-weight: 600;
  margin-bottom: 0.5rem;
}

.fee-breakdown ul {
  list-style-type: disc;
  padding-left: 1rem;
}

.phone-info {
  display: none;
}

/* TABLET SCREENS */
@media (max-width: 1024px) {
  .new-admission-main {
    font-size: 14px;
    max-width: auto;
    margin: 0 auto;
  }

  .new-admission {
    grid-template-columns: 1fr;
    gap: 1rem;
  }

  .new-admission > ul {
    list-style-type: none;
    width: auto;
    padding: 0 1rem;
  }
}

/* Media Query for mobile layout */
@media (max-width: 768px) {
  .new-admission > ul {
    list-style-type: none;
    width: auto;
    padding: 0;
  }

  .phone-info {
    display: block;
  }

  .laptop-info {
    display: none;
  }
}

/* PHONE SCREENS */
@media (max-width: 575px) {
  .school-time li {
    grid-template-columns: 165px 20px 140px;
    padding-left: 0;
  }

  .new-admission article h2 {
    font-size: 1.1rem;
  }

  .phone-info {
    display: block;
  }
}
