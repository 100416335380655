#staff-section {
  padding: 7% 1rem;
  /* background: url(/public/cool-background.svg);
  background-size: cover;
  background-position: 0% 0; */
}

.staff-container {
  max-width: 1200px;
  margin: 0 auto;
}

.staff-container h1 {
  color: var(--main-bg-color);
  font-size: 3rem;
  font-weight: 600;
  margin-bottom: 0rem;
}

.staff-container h3 {
  color: var(--main-bg-color);
  font-size: 1.3rem;
  margin-bottom: 2.5rem;
}

.staff-container > p {
  color: var(--main-bg-color);
  text-align: center;
  max-width: 1000px;
  margin: 0 auto;
}

.staff-container-flex {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 1rem;
  margin-bottom: 4rem;
}

.staff-container-flex figure {
  position: relative;
  height: 300px;
  flex: 1;
  border-radius: 50%;
  transition: 0.2s all;
}

.staff-container-flex figure:hover {
  flex: 2;
}

.staff-container-flex figure img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 100%;
  transition: 0.2s all;
}

.staff-container-flex figure:hover img {
  border-radius: 15px;
  /* transform: scale(1.1); */
}

.staff-container-flex figure:hover > figcaption {
  visibility: visible;
  opacity: 1;
}

.staff-container-flex figcaption {
  position: absolute;
  left: 0px;
  bottom: 0px;
  width: 100%;
  z-index: 100;
  color: var(--text-white);
  background-color: rgba(0, 0, 0, 0.2);
  padding: 10px;
  border-radius: 10px;
  visibility: hidden;
  opacity: 0;
  transition: opacity 0.3s ease;
}

.staff-container-flex figcaption > * {
  margin-bottom: 0.5rem;
}

.staff-container-flex figcaption p:first-child {
  font-size: 1rem;
  border-left: 5px solid var(--text-white);
  padding-left: 10px;
}

.staff-container-flex figcaption p:nth-child(2) {
  font-size: 1rem;
  border-left: 5px solid var(--text-white);
  padding-left: 10px;
  margin-bottom: 1.5rem;
}

.figcaption-link {
  margin-left: 1rem;
  padding: 0.5rem 1rem;
  font-weight: 600;
  color: var(--text-white);
  background-color: var(--primary-darkBlue);
  border-radius: 5px;
  cursor: pointer;
}

/* PRINCIPAL's SPEECH */
.speech-grid {
  display: grid;
  grid-template-columns: 200px 1fr 150px;
  gap: 2rem;
  align-items: flex-start;
  padding: 1.5rem 1.5rem;
  margin: 0 auto;
  border: 6px solid var(--subtle-background);
  color: var(--text-white);
  background-color: var(--main-bg-dark);
  border-radius: 25px;
  box-shadow: 1px 1px 1px 1px rgba(0, 0, 255, 0.02);
}

.speech-grid article:first-child {
  overflow: hidden;
  border-radius: 100px;
}

.speech-grid img {
  object-fit: cover;
  border-radius: 100px;
  transition: 0.2s all;
}
.speech-grid img:hover {
  transform: scale(1.1);
}

.speech-grid h3 {
  color: var(--text-white);
  font-size: 1.75rem;
  font-weight: 600;
  margin-bottom: 1rem;
}

.speech-grid blockquote {
  line-height: 1.6;
  text-align: justify;
}

.read-more {
  justify-self: center;
  align-self: flex-end;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  font-weight: 600;
  color: var(--main-bg-color);
  padding: 0.5rem 1rem;
  background-color: var(--text-white);
  border: 2px solid transparent;
  border-radius: 50px;
  transition: 0.05s all;
}

.read-more:hover {
  background-color: var(--text-white);
  color: var(--text-white);
  border-color: var(--text-white);
}

/****** NEW SECTION ******/
/* HEADTEACHER'S SPEECH COMPONENT STYLES */
.headteacher-speech {
  text-align: justify;
  line-height: 1.8;
  border-bottom: 2px solid var(--subtle-background);
  padding-bottom: 1rem;
}

.headteacher-speech h1 {
  font-size: 2.5rem;
  margin-bottom: 2rem;
}
.headteacher-speech .heading {
  display: grid;
  grid-template-columns: 300px 1fr;
  gap: 1rem;
  margin-bottom: 1.5rem;
}
.headteacher-speech img {
  align-self: stretch;
  object-fit: cover;
  border-radius: 5px;
}

.headteacher-speech p {
  margin-bottom: 1rem;
}

.headteacher-speech blockquote {
  font-size: 1rem;
  text-align: right;
}

/* RESPONSIVE STYLES */
@media (max-width: 992px) {
  .headteacher-speech .heading {
    grid-template-columns: 1fr;
    gap: 1rem;
  }
  .headteacher-speech h1 {
    font-size: 2rem;
    margin-bottom: 2rem;
  }
}

@media (max-width: 768px) {
  .staff-container-flex {
    grid-template-columns: repeat(2, 1fr);
    margin-bottom: 2rem;
  }

  .speech-grid {
    grid-template-columns: 1fr;
    justify-items: center;
    row-gap: 2rem;
    padding-bottom: 2rem;
  }

  .speech-grid article:first-child {
    border-radius: 100%;
  }

  .speech-grid img {
    height: 350px;
    border-radius: 100%;
    /* width: 100%; */
  }

  .speech-grid h3 {
    font-size: 1.75rem;
    line-height: 1.2;
    margin-bottom: 0.5rem;
  }

  .headteacher-speech h1 {
    font-size: 1.5rem;
    margin-bottom: 1.25rem;
  }
}

@media (max-width: 575px) {
  .staff-container-flex {
    grid-template-columns: 1fr;
    margin-bottom: 1.5rem;
  }

  .staff-container h1 {
    font-size: 2rem;
    line-height: 1.1;
    margin-bottom: 1rem;
  }
  .staff-container h3 {
    font-size: 1.1rem;
    line-height: 1.4;
  }

  .staff-container-flex {
    flex-direction: column;
    justify-content: space-between;
    gap: 1rem;
    margin-bottom: 4rem;
  }

  .staff-container-flex figure {
    margin-bottom: 1rem;
  }

  .staff-container-flex figure img {
    border-radius: 10px;
    margin-bottom: 1rem;
  }

  .staff-container-flex figcaption {
    /* position: relative; */
    /* color: #000; */
    /* padding: 0; */
    visibility: visible;
    opacity: 1;
    /* background-color: white; */
  }

  .staff-container-flex figcaption > * {
    margin-bottom: 0.15rem;
  }

  .staff-container-flex figcaption p:nth-child(2) {
    margin-bottom: 0.75rem;
  }

  .headteacher-speech .heading {
    grid-template-columns: 1fr;
    margin-bottom: 1.25rem;
  }
}
