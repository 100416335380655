#news-section {
  padding: 3rem 2rem;
}

.news-container {
  display: grid;
  grid-template-columns: 70% 28%;
  gap: 1rem;
  max-width: 1200px;
  margin: 0 auto;
}

.news-container > h1 {
  padding: 0.5rem 0;
}
.news-container h2 {
  color: var(--main-bg-dark);
  font-size: 1.1rem;
  font-weight: 600;
}

/* EVENTS */
.events {
  border: 2px solid var(--subtle-background);
  border-radius: 10px;
  padding: 0 0 1rem;
}

.events header {
  display: flex;
  align-items: center;
  background-color: var(--subtle-background);
  border-top: transparent;
}

.announcement-open,
.event-open {
  color: var(--main-bg-dark);
  background-color: #fff;
  border-top: 4px solid var(--main-bg-dark);
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}

.news-header > h1 {
  color: #000;
  /* font-size: 1.2rem; */
  font-weight: 600;
  padding: 0.5rem 1rem;
  cursor: pointer;
}

.event-details {
  padding: 1rem 1rem;
}

.event-details .slick-slide {
  padding: 0 1rem 0.5rem;
  border-right: 2px solid var(--subtle-background);
}

.announcement-details,
.event-detail {
  display: flex !important;
  flex-direction: column;
  gap: 0.75rem;
}

/* LATEST NEWS */
.latest-news {
  font-size: 15px;
  border: 2px solid var(--subtle-background);
  border-radius: 10px;
}
.latest-news h1 {
  /* font-size: 1.2rem; */
  font-weight: 600;

  padding: 0.5rem 1rem;
  border-bottom: 2px solid var(--subtle-background);
}

.latest-news-content {
  padding: 0.5rem 2rem 2rem;
}

.news {
  display: flex !important;
  flex-direction: column;
  gap: 1rem;
}

.slick-slide {
  padding: 0 0 0.5rem;
}

.news p:first-child {
  text-align: right;
}

.news-btn {
  text-align: center;
  color: var(--text-white);
  font-weight: 600;
  width: auto;
  /* margin-bottom: 1rem; */
  align-self: flex-end;
  padding: 0.5rem 1rem;
  border-radius: 5px;
  background-color: var(--main-bg-dark);
}

.news-btn:hover {
  opacity: 0.9;
}

.blinking {
  background-color: red;
  animation: blink 1s infinite;
}

@keyframes blink {
  0% {
    background-color: red;
  }
  50% {
    background-color: white;
    color: red;
  }
  100% {
    background-color: red;
  }
}

@media (max-width: 992px) {
}

@media (max-width: 768px) {
  .news-container {
    grid-template-columns: 100%;
    row-gap: 2rem;
  }

  .new-registration {
    padding: 2rem 5%;
  }
  .new-registration h1 {
    text-align: center;
    font-size: 1.5rem;
  }

  .new-registration > div {
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }
}

@media (max-width: 575px) {
  #news-section {
    padding: 3rem 1rem;
  }
}
