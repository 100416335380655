.calendar-header,
.admission-header,
.contact-header,
.events-header {
  max-width: 1368px;
  margin: 0 auto;
  margin-bottom: 5rem;
}

.calendar-header,
.admission-header,
.contact-header,
.events-header {
  position: relative;
  display: flex;
  align-items: center;
  color: var(--text-white);
  padding: 0 2rem;
  height: 20rem;
  background-size: cover;
  background-position: center center;
  border-bottom-left-radius: 25px;
  border-bottom-right-radius: 25px;
}

.calendar-header {
  background: url(/public/library.jpg);
  background-size: cover;
  background-position: center;
}

.calendar-header::before,
.admission-header::before,
.contact-header::before,
.events-header::before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  border-bottom-left-radius: 25px;
  border-bottom-right-radius: 25px;
  background: linear-gradient(to right, rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0.6));
  z-index: 1;
}

.calendar-header-container,
.admission-header-container,
.contact-header-container,
.events-header-container {
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  z-index: 2;
}
.calendar-header h1,
.admission-header h1,
.contact-header h1,
.events-header h1 {
  font-size: 4.5rem;
  font-weight: 600;
  line-height: 1;
  margin-bottom: 1rem;
  text-shadow: 1px 1px 6px rgba(0, 0, 0, 0.3);
}
.calendar-header h2,
.admission-header h2,
.contact-header h2 {
  font-size: 1.3rem;
  font-weight: 600;
}

.calendar-main,
.events-main {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 2rem 4rem;
  overflow-x: scroll;
}

.calendar-main p {
  margin-bottom: 2rem;
}

/* CALENDAR TABLE DESIGIN */

table {
  width: 100%;
  border-collapse: collapse;
  /* margin-bottom: 1.5rem; */
}

.table-scroll {
}

th {
  color: var(--text-white);
  background-color: var(--main-bg-dark);
  text-align: center;
  padding: 0.625rem;
  margin: 0 16px;
  font-weight: 600;
  border-bottom: 2px solid var(--subtle-background);
  border-radius: 10px;
}

td {
  padding: 1rem 0.625rem;
  border-bottom: 1px solid var(--subtle-bg-dark);
}

tr:nth-child(even) {
  background-color: #f2f2f2;
}

tr:hover {
  background-color: #ddd;
}

td:first-child {
  font-weight: 600;
  transform: rotate(-15deg);
}

/* TABLET SCREENS */
@media (max-width: 992px) {
  .calendar-main,
  .events-main {
    padding: 0 1.5rem 4rem;
  }

  th {
    font-size: 10px;
  }
  .calendar-main {
    margin: 0 2rem;
    padding: 0 0 1rem;
  }
}

/* Media Query for mobile layout */
@media (max-width: 768px) {
  .calendar-header,
  .admission-header,
  .contact-header,
  .events-header {
    height: 15rem;
  }

  .calendar-header h1,
  .admission-header h1,
  .contact-header h1,
  .events-header h1 {
    font-size: 2.5rem;
    font-weight: 600;
    margin-bottom: 1rem;
  }
  .calendar-header h2,
  .admission-header h2,
  .contact-header h2 {
    font-size: 1.1rem;
    font-weight: 600;
  }
}

@media (max-width: 575px) {
  th,
  td {
    padding: 5px;
    font-size: 12px;
  }

  th {
    font-size: 10px;
  }

  .month {
    transform: rotate(-90deg);
  }

  .events-main {
    padding: 0 1.5rem 4rem;
  }
}
