/* TESTIMONIAL SECTION */
.testimonial-section {
  padding: 4rem 1rem 7rem;
}

.testimonial-container {
  max-width: 1200px;
  margin: 0 auto;
}

.testimonial-container h1 {
  font-size: 2.5rem;
  font-weight: 600;
  color: var(--main-bg-dark);
  margin-bottom: 3rem;
}

.testimonial-flex-container {
  /* display: flex !important; */
  justify-content: space-around;
  align-items: baseline;
  row-gap: 3rem;
  flex-flow: wrap;
  max-width: 1000px;
  margin: 0 auto;
}

.testimonial-flex-container .slick-arrow {
  background-color: rgb(200, 228, 255);
  background-color: var(--primary-darkBlue);
  border-radius: 50px;
  width: 50px;
  height: 50px;
}

.testimonial-flex-container .slick-prev {
  left: -100px;
}
.testimonial-flex-container .slick-next {
  right: -100px;
}

.testimony {
  display: flex !important;
  flex-direction: column;
  align-items: center;
  text-align: center;
  gap: 1rem;
  font-size: 0.9rem;
  padding: 0 1.25rem;
  width: 275px;
}

.testimony img {
  width: 150px;
  height: 150px;
  object-fit: cover;
  border-radius: 100%;
  margin-bottom: 0.75rem;
}

.testimony q {
  /* margin-bottom: 1rem; */
  font-style: italic;
  opacity: 0.7;
}

.testimony .line {
  /* width: 50px; */
  height: 3px;
  background-color: var(--subtle-background);
}

.testimony h3 {
  font-weight: 600;
  color: var(--primary-darkBlue);
}

.testimony span {
  color: gray;
}

@media (max-width: 1200px) {
  .testimonial-flex-container .slick-arrow {
    display: none !important;
  }
}

@media (max-width: 992px) {
}

@media (max-width: 768px) {
  .testimonial-section {
    padding: 4rem 1rem;
  }

  .testimonial-container h1 {
    font-size: 2rem;
  }
}

@media (max-width: 575px) {
  .testimonial-container h1 {
    font-size: 1.75rem;
  }
}
