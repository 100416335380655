@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://fonts.googleapis.com/css2?family=Lexend+Tera:wght@400;600;700&family=Merriweather:wght@400;700&family=Montserrat:wght@400;500;600;700&family=Open+Sans:ital,wght@0,400;0,500;0,700;1,600&family=Rubik:wght@400;500;600;700&display=swap");

:root {
  --box-shadow: 3px 3px 6px 3px rgba(0, 0, 0, 0.1);
  /* --gradient-bg: linear-gradient(to right, #daf2f7, #b2e8f1); */
  --section-bg: linear-gradient(
    to top,
    rgb(255, 255, 255),
    rgba(245, 253, 255, 0.9),
    rgba(216, 244, 255, 0.8),
    rgb(25, 51, 84, 0.2),
    rgba(178, 228, 255, 0.2),
    rgba(245, 253, 255, 0.8),
    rgba(248, 253, 255, 0.8)
  );

  --primary-color: #00b4d8;
  --secondary-color: #0077b6;
  --text-color: #caf0f8;
  --main-bg-color: #03045e;

  --tertiary-color: #90e0ef;
  --tertiary-color: rgba(144, 225, 239, 0.3);

  --main-bg-dark: #001a41;
  --main-bg-dark: #193354;
  --main-bg-dark-navbar: rgba(0, 26, 65, 0.7);

  --bg-blue: #ecf5fd;
  /* CHATGPT COLOUR PALETTE */

  --primary-darkBlue: #0b3d91;
  --content-background: #66a3d2;
  --content-background: #98ceed;
  --text-white: #fff;
  --accent: #ffd700;
  --complementary-accent: #228b22;
  --subtle-bg-dark: #ccc;
  --subtle-background: #f5f5f5;
  /* --subtle-background: #f5f5f53f; */

  /* Gemini Logo Based Color Pallette */
  --blue-dark: #193354;
  --blue-light: #98ceed;
  --gray-light: #dadada;
  --white: #ffffff;
}

* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

/* 
@layer base {
  html {
    font-family: Merriweather;
  }
} */

html {
  font-size: 100%;
  /* scroll-behavior: smooth; */
}
body {
  font-family: "Lexend Tera", sans-serif;
  /* margin-top: 69px; */
  font-family: "Rubik", sans-serif;
  font-family: "Inter", sans-serif;
  font-family: "Montserrat", sans-serif;
  font-family: "Open Sans", sans-serif;
  position: relative;

  /* font-weight: 500; */
}

h1,
h2,
h3 {
  font-family: "Merriweather", sans-serif;
  font-family: "Lexend Tera", sans-serif;
  font-family: "Rubik", sans-serif;
  font-family: "Inter", sans-serif;
  font-family: "Open Sans", sans-serif;
  font-family: "Montserrat", sans-serif;

  line-height: 1.2;
  /* letter-spacing: -0.12rem; */
  /* text-transform: uppercase; */
}

p {
  /* color: rgb(70, 70, 70); */
}

.main-header {
  width: 100%;
  color: #fff;
}

/* TABLET SCREENS */
@media (max-width: 1024px) {
}

/* Media Query for mobile layout */
@media (max-width: 768px) {
  .testimonial-flex-container {
    flex-direction: column;
    row-gap: 3rem;
    /* flex-flow: wrap; */
  }

  .footer {
    padding: 0 1rem;
  }

  .footer-container {
    grid-template-columns: 1fr;
  }
}

@media (max-width: 575px) {
  .construction {
    padding: 5rem 7rem;
  }
}

.construction {
  padding: 5rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 2rem;
  text-align: center;
}

/* .construction > * {
  text-align: center;
} */
