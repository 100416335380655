/* Navbar Styles */
.navbar {
  position: relative;
  width: 100%;
  /* height: 50px; */
  padding: 0 2rem;
  margin: 0 auto;
  z-index: 1000;
  color: var(--main-bg-color);
  color: #fff;
  background-color: transparent;
  background-color: var(--main-bg-dark);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  transition: background-color 0.3s ease-in-out;
}

.navbar.sticky {
  position: sticky;
  top: 0;
  left: 0;
  color: var(--text-white);
  background-color: var(--main-bg-dark-navbar);
  box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.1);
  transition: 0.3s all;
}

.navbar.stic ky:hover {
  color: var(--text-white);
}

.navbar.sticky .nav-links > li {
  padding: 0rem 0rem;
}

.navbar.sticky .nav-link {
  padding: 0.75rem 1rem;
  border-bottom: 4px solid transparent;
}

.navbar.sticky .nav-link:hover {
  border-bottom-color: var(--text-white);
}

/* PREVIOUS CODE */
/* .active {
  border-bottom: 2px solid var(--text-white);
} */

/* NAVBAR CONTAINER */
.navbar-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  column-gap: 1rem;
  max-width: 1200px;
  margin: 0 auto;
}

.navbar-container .logo-link {
  display: flex;
  justify-content: center;
  gap: 8px;
  font-size: 1.5rem;
  font-weight: 600;
}

.navbar-logo {
  display: none;
}

.menu {
  position: relative;
  background: transparent;
}

.nav-links {
  position: relative;
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.nav-links:hover > * {
  opacity: 0.3;
}

.nav-links:hover > *:hover {
  opacity: 1;
}

.nav-links > li {
  padding: 1rem 1rem;
}

.nav-links .nav-link {
  color: var(--text-white);
  font-size: 0.9375rem;
  font-weight: 500;
  /* color: #fff; */
  letter-spacing: -0.3px;
  padding: 0.5rem 0rem;
  border-bottom: 1px solid transparent;
}

.nav-link:hover {
  /* color: #fff; */
  border-bottom-color: #fff;
}

.contact-info {
  display: none;
}

.dropdown-nav-link {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 0.25rem;
}

.dropdown {
  display: flex;
  flex-direction: column;
  min-width: 200px;
  background-color: #fff;
  border-radius: 10px;
  padding: 0.5rem 0.5rem;

  position: absolute;
  top: 100%;
  z-index: 1;
  opacity: 0;
  visibility: hidden;
  transform: scale(0.37) translateX(0px);
  transition: 0.15s ease-in-out;
  box-shadow: 1px 1px 6px rgba(0, 0, 0, 0.1), -1px -1px 6px rgba(0, 0, 0, 0.1);
}

li:hover > .dropdown {
  visibility: visible;
  opacity: 1;
  transform: scale(1) translateX(5px);
}

.dropdown-link {
  display: inline-block;
  color: var(--main-bg-color);
  font-size: 0.9375rem;
  padding: 0.5rem;
  width: 100%;
}

.dropdown-link:hover {
  color: var(--main-bg-dark);
  border-radius: 5px;
  background-color: rgba(0, 0, 0, 0.05);
  transition: background-color 0.15s ease-in;
}

.nav-icon {
  display: none;
  font-size: 1.5rem;
  cursor: pointer;
}

.nav-icon {
  padding: 1em 0;
}

@media (max-width: 1200px) {
}

/* Small desktop and laptop-specific styles */
@media (min-width: 769px) and (max-width: 992px) {
}

@media (max-width: 1024px) {
  .navbar-logo {
    display: block;
    color: var(--main-bg-color);
    font-size: 1.875rem;
    font-weight: 600;
    margin-bottom: 2rem;
  }

  .navbar.sticky .nav-links > li {
    padding: 0.25rem 0rem;
  }

  .navbar.sticky .nav-link {
    padding: 0;
    border-bottom: none;
  }

  /* MENU */

  .menu {
    visibility: hidden;
    opacity: 0;
    position: fixed;
    top: 0px;
    left: 0;
    z-index: 1000;

    min-height: 100vh;
    width: 40%;
    height: 100%;
    overflow-y: scroll;
    padding: 1rem 1.5rem;

    /* color: var(--text-white); */
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
    background: var(--subtle-background);
    /* background: var(--main-bg-dark); */
    box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.4);
    transform: translateX(-100%);
    transition: 0.3s ease-in-out;
  }

  .menu.show {
    visibility: visible;
    opacity: 1;
    transform: translateX(0%);
  }

  .nav-links {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: stretch;
    row-gap: 1rem;
    margin-bottom: 3.5rem;
  }

  .nav-links > li {
    padding: 0.25rem 0rem;
  }

  .nav-links:hover > * {
    opacity: 1;
  }

  .nav-links .nav-link {
    display: flex;
    justify-content: space-between;
    color: var(--main-bg-dark);
    width: 100%;
    /* font-size: 1.1rem; */
    margin: 0;
    padding: 0;
    /* border-bottom: 2px solid white; */
  }

  .contact-info {
    display: block;
    color: var(--main-bg-color);
    font-size: 1.2rem;
    font-weight: 600;
    margin-top: auto;
  }
  .contact-info p {
    margin-bottom: 1rem;
  }

  /* DROPDOWN */

  .dropdown {
    display: none;
    min-width: 100%;
    position: static;
    top: 0;
    left: 0;
    visibility: visible;
    opacity: 1;
    transform: none;
    background-color: transparent;
    max-height: 500px;
    transition: 0.3s all;
    overflow: hidden;
    box-shadow: none;
  }

  .dropdown.open {
    display: block;
    /* max-height: 500px; */
    border-top: none;
  }

  /* Animation for showing the navbar */
  .hamburger-menu,
  .close-icon {
    display: block;
    position: relative;
  }
}

/* MOBILE SPECIFIC STYLES */
@media (max-width: 575px) {
  .navbar {
    padding: 0 1rem;
  }

  .navbar-container h1 {
    font-size: 80%;
  }

  .menu {
    width: 80%;
  }

  .nav-icon {
    font-size: 1rem;
  }

  .nav-icon {
    padding: 0.75em 0;
  }
}

@media (max-width: 992px) {
}

@media (max-width: 768px) {
}
