#contact-page {
  /* padding: 0 2rem; */
}

#social-links {
  background: var(--subtle-background);
}

/* CONTACT Header */

.header-container {
  padding: 7.5rem 0;
  max-width: 1200px;
  margin: 0 auto;
  text-align: center;
}

.contact-header {
  background-image: url("/public/school_view.jpg");
  background-size: cover;
  background-position: center;
}

.contact-header .contact-header-container h1 {
  letter-spacing: -2px;
}

#contact-details {
  padding: 0rem 2rem 5rem;
}

.contact-details-container {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 2.5rem 0;
  max-width: 1200px;
  margin: 0 auto;
}

.contact-details-container > div {
  display: flex;
  justify-content: flex-start;
  align-items: flex-end;
  gap: 1.25rem;
  text-align: center;
}

.contact-details-container > div > div {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 0.25rem;
  text-align: center;
}

.contact-details-container p:first-child {
  font-size: 0.875rem;
}
.contact-details-container p {
  font-size: 0.9375rem;
  text-align: left;
}

.contact-details-container .text {
  color: var(--main-bg-dark);
  font-size: 1.2rem;
}

#contact-cards {
  padding: 2rem 2rem;
  background: linear-gradient(
    to right,
    rgba(228, 228, 228, 0.7),
    rgba(228, 228, 228, 0.8)
  );
  display: none;
}

.cards-container {
  max-width: 1200px;
  margin: 0 auto;
}
.cards-container h1 {
  font-size: 2rem;
  margin-bottom: 1rem;
}

/* SOCIAL LINKS */
#social-links {
  padding: 5rem 2rem;
}

.social-container {
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  gap: 2rem 2rem;
  margin-top: 1.5rem;
}

/* FAQ */
#faq {
  padding: 5rem 1rem 5rem;
  background: var(--section-bg);
  /* background: url("/public/cool-background.svg"); */
  background-size: cover;
}

.faq-container {
  max-width: 1200px;
  margin: 0 auto;
}

/* MAP SECTION */
#google-map {
  background: var(--main-bg-dark);
}
.map-container {
  display: grid;
  /* grid-template-columns: repeat(autofit, minmax(600px, 1fr)); */
  grid-template-columns: 1fr;
}

.map-container h1 {
  text-align: center;
  line-height: 1.1;
  font-size: 3rem;
  font-weight: 600;
  color: var(--text-white);
  padding: 5rem 0;
}

.map {
  width: 100%;
}

/* MEDIA QUERIES */
@media (max-width: 1024px) {
}

@media (max-width: 992px) {
}

@media (max-width: 768px) {
  #contact-details {
    padding: 0rem 2rem 2rem;
  }
}

@media (max-width: 575px) {
  .header-container {
    padding: 3.5rem 0;
  }

  .header-container h1 {
    font-size: 1.75rem;
    letter-spacing: -1px;
  }

  #google-map {
    padding-top: 2rem;
  }
  .map-container {
    grid-template-columns: 1fr;
    row-gap: 3rem;
  }

  .map-container h1 {
    font-size: 2rem;
    padding: 1rem 0;
  }
}

/* accordion link */
.faq-link {
  color: red; /* Example color for links */
  text-decoration: underline; /* Underline links */
}
