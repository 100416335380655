.about-section {
  padding: 5rem 1rem;
  background: var(--section-bg);
  border-top-left-radius: 500px;
  border-top-right-radius: 500px;
  border-bottom-left-radius: 300px;
  border-bottom-right-radius: 300px;
}

/* ABOUT CONTAINER */
.about-container {
  max-width: 1200px;
  margin: 0 auto;
  text-align: center;
}

/* ABOUT HEADING */
.about-container header {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin-bottom: 4rem;
}

.about-container .title {
  color: var(--main-bg-dark);
  font-size: 5rem;
  font-weight: 600;
  background: linear-gradient(
      to bottom var(--section-bg),
      var(--text-white),
      var(--section-bg)
    )
    repeat-y;
  background-position: center;
  background-size: cover;
}

.about-container .motto {
  font-size: 1rem;
  font-style: italic;
}
.about-container .motto span {
  font-weight: 700;
}

.about-section .subtitle {
  color: var(--main-bg-dark);
  font-size: 2rem;
  font-weight: 600;
}

.about-section p.intro {
  font-size: 1.05rem;
  line-height: 1.8;
  max-width: 800px;
  margin: 0 auto;
}

.about-container .btn {
  display: flex;
  align-items: center;
  column-gap: 1rem;
  align-self: center;
  padding: 1rem 3.5rem;
  font-size: 1.2rem;
  font-weight: 500;
  color: var(--text-white);
  background-color: var(--main-bg-dark);
  border-radius: 50px;
  border: 4px solid transparent;
  transition: 0.2s ease-in-out;
}

.btn:hover {
  background-color: transparent;
  color: var(--main-bg-dark);
  border-color: var(--main-bg-dark);
}
.btn:focus {
  color: var(--text-white);
  background-color: var(--main-bg-dark);
}

.link-arrow {
  transition: 0.4s ease-in-out;
}

.btn:hover .link-arrow {
  transform: translateX(15px);
}

/* ABOUT IMAGE GRID */

.about-section .img-grid {
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  grid-template-rows: repeat(8, 1fr);
  gap: 10px;
  height: 400px;
  margin-bottom: 4rem;
}

.img-grid .img-container {
  width: 100%;
}

.img-grid .img-container img {
  width: auto;
  height: auto;
  object-fit: cover;
  border-radius: 10px;
  box-shadow: var(--box-shadow);
  transition: 0.2s all;
}
.img-grid .img-container img:hover {
  transform: scale(1.7);
}

.img-grid .img-1 {
  /* grid-area: img1; */
  grid-column: 1 / 4;
  grid-row: 3/ 6;
}
.img-grid .img-2 {
  /* grid-area: img2; */
  grid-column: 3 / 6;
  grid-row: 1 / 4;
}
.img-grid .img-3 {
  /* grid-area: img3; */
  grid-column: 5 / 9;
  grid-row: 2 / 8;
}
.img-grid .img-4 {
  /* grid-area: img4; */
  grid-column: 8 / 11;
  grid-row: 1 / 3;
}
.img-grid .img-5 {
  /* grid-area: img5; */
  grid-column: 10 / -1;
  grid-row: 4 / 8;
}

/* MOBILE GRID IMAGES SLIDER */
.mobile-grid {
  display: none;
  margin-bottom: 4rem;
}

.mobile-grid img {
  border-radius: 10px;
  height: 200px;
  object-fit: cover;
}

/* CORE VALUES */

.values h1 {
  font-size: 2.5rem;
  font-weight: 600;
  margin-bottom: 2rem;
}

.core-values-list {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  gap: 2.5rem;
}

.core-values-list {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  /* justify-content: space-around; */
  /* flex-wrap: wrap; */
  gap: 1.5rem;
}

.core-values-list li {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1.5rem;
  font-size: 1.8rem;
}

.core-values-list p {
  font-size: 14px;
  color: rgb(64, 64, 64);
}

.core-values-list li span {
  margin-top: auto;
}

.core-values-list .icon {
  /* color: var(--main-bg-dark); */
}

/* STATS SUB-SECTION */
.stats-section {
  background-color: var(--text-white);
  padding: 4rem 0;
}

.stats-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 4rem;
  flex-wrap: wrap;
  max-width: 800px;
  margin: 0 auto;
}

.stats {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.stats span {
  font-size: 3rem;
  font-weight: 600;
  letter-spacing: 4px;
  color: var(--main-bg-dark);
}
.stats p {
  font-size: 1.6rem;
}

@media (max-width: 1024px) {
  .core-values {
    flex-direction: column;
    gap: 1.8rem;
  }
}

@media (max-width: 992px) {
  .about-section {
    padding: 5rem 1rem 0;
    background: var(--section-bg);
  }

  .about-container .title {
    font-size: 3rem;
    line-height: 1.1;
  }
  .about-section .subtitle {
    font-size: 1.5rem;
  }

  .about-container .btn {
    padding: 0.75rem 2rem;
    font-size: 1rem;
  }

  .about-section .img-grid {
    height: 250px;
  }
}

@media (max-width: 768px) {
  .about-section {
    border-radius: 0;
  }
  .about-section .img-grid {
    display: none;
  }

  .about-container .motto {
    margin-bottom: 1rem;
  }

  .mobile-grid {
    display: block;
    /* padding: 0 1rem; */
  }

  .mobile-grid .slick-arrow {
    display: none !important;
  }

  .values h1 {
    font-size: 2rem;
    margin-bottom: 1.5rem;
  }

  .core-values-list {
    gap: 1.5rem;
  }

  .core-values-list li {
    font-size: 1.25rem;
  }
}

@media (max-width: 575px) {
  .about-section {
    padding: 4rem 1rem 0;
    background: var(--section-bg);
  }

  .about-section .img-grid {
    height: 150px;
    margin-bottom: 2rem;
  }

  .core-values-list {
    grid-template-columns: 1fr;
    gap: 1.5rem;
  }

  .core-values-list li {
    gap: 0.5rem;
    font-size: 1.6rem;
  }

  .stats-container {
    flex-direction: column;
    gap: 2rem;
  }

  .stats {
    padding: 1rem 2rem;
    box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.2);
    border-radius: 5px;
  }

  .stats:first-child {
    align-self: flex-start;
  }
  .stats:last-child {
    align-self: flex-end;
  }
}
