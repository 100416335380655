.footer {
  position: relative;
  color: white;
  font-size: 0.875rem;
  background-color: black;
  background-color: var(--primary-darkBlue);
  background-color: var(--main-bg-color);
  background-color: var(--main-bg-dark);
  /* background: var(--gradient-bg); */
  /* background: var(--section-bg); */
  padding: 5rem 2rem 4rem;
}

.footer-container {
  display: grid;
  grid-template-columns: 175px 1fr 1fr 1fr 1fr;
  gap: 4rem;
  max-width: 1200px;
  margin: 0 auto 3rem;
}

.footer-container article h3 {
  border-bottom: 1px solid var(--subtle-background);
  padding-bottom: 0.5rem;
  margin-bottom: 0.5rem;
  font-size: 1.1rem;
}

.footer-container ul {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.footer-container ul .link {
  border-bottom: 1px solid transparent;
  padding-bottom: 0.15rem;
  transition: 0.2s all;
}

.footer-container ul .link:hover {
  border-color: var(--text-white);
}

.logo-col img {
  height: 200px;
  width: 220px;
}

.footer .base {
  display: flex;
  justify-content: space-between;
  align-items: center;
  column-gap: 4rem;
  padding: 1rem 0 0;
  border-top: 1px solid var(--text-white);
  max-width: 1200px;
  margin: 0 auto;
}

.footer .base img {
  width: 75px;
  height: 75px;
}

.base .base-logo {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.base-logo > div {
  display: flex;
  flex-direction: column;
  font-family: "Lexend Tera", sans-serif;
  letter-spacing: -1.5px;
  font-size: 0.75rem;
  text-align: left;
  text-transform: uppercase;
}

.social-links {
  display: flex;
  justify-content: flex-start;
  gap: 2rem;
}

.social-links > *:hover {
  color: var(--secondary-color);
  cursor: pointer;
}

.toTop {
  position: absolute;
  top: 0%;
  right: 0;
  padding: 5px 0.5rem;
  cursor: pointer;
}

@media (max-width: 1024px) {
  .footer {
    padding: 4.5rem 1.5rem;
  }

  .footer-container {
    grid-template-columns: 1fr 1fr 1fr;
  }
}

@media (max-width: 768px) {
  .footer {
    padding: 3.5rem 1.25rem;
  }

  .footer-container {
    grid-template-columns: 1fr 1fr;
  }
}

@media (max-width: 575px) {
  .footer {
    padding: 3.5rem 1.5rem;
  }

  .footer-container {
    grid-template-columns: 1fr;
  }

  .footer-container ul {
    gap: 1rem;
  }

  .footer .base {
    flex-direction: column;
    row-gap: 2rem;
    padding: 2rem 0;
    text-align: center;
  }
}
