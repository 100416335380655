.accordion {
  margin: 0rem auto;
}

.accordion-item {
  max-width: 1000px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
}

.item {
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  padding: 1.25rem 1.5rem;
  padding-right: 3rem;
  cursor: pointer;
  border-top: 4px solid #fff;
  border-bottom: 4px solid #fff;
  border-radius: 25px;

  max-height: auto;
  transition: max-height 0.3s ease-in-out;

  /* display: grid;
  grid-template-columns: auto 1fr auto;
  column-gap: 1.5rem;
  row-gap: 2rem;
  align-items: center; */
}

.item > div:first-child {
  display: grid;
  grid-template-columns: auto 1fr auto;
  column-gap: 1.5rem;
  row-gap: 2rem;
  align-items: center;
}

.number {
  font-size: 1.5rem;
  font-weight: 500;
  color: var(--complementary-accent);
}

.title,
.icon {
  font-size: 1.25rem;
  font-weight: 500;
}

.content-box {
  grid-column: 2 / -1;
  /* padding-bottom: 16px;
  padding-left: 2.9rem; */
  padding: 0.5rem 0 1rem 2.9rem;
  line-height: 1.6;
  max-height: auto;
  opacity: 0;
  transition: 0.2s ease-out;
}

.content-box ul {
  /* color: var(--complementary-accent); */
  margin-left: 16px;
  margin-top: 16px;

  display: flex;
  flex-direction: column;
  gap: 2rem;
  list-style-type: circle;
}

/* OPEN STATE */
.item.open {
  max-height: auto;
}

.open {
  border-top: 4px solid var(--complementary-accent);
}

.open .number,
.open .text {
  color: var(--complementary-accent);
}

.open .content-box {
  opacity: 1;
}

/* Media Query for mobile layout */
@media (max-width: 768px) {
  .number {
    font-size: 1.05rem;
  }

  .title,
  .icon {
    font-size: 1.05rem;
    font-weight: 500;
  }

  .item {
    padding: 1.25rem 1.25rem;
    padding-right: 1rem;

    grid-template-columns: auto 1fr auto;
    column-gap: 1.25rem;
  }
}

/* accordion link */
.faq-link {
  color: red; /* Example color for links */
  text-decoration: underline; /* Underline links */
}
